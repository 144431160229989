@import "styles/App.scss";

.versionSelector  {
  width: 100%;
}

.versionSelectorDescription {
  margin-top: 25px;
  margin-bottom: 6px;
}

.fileDownloadButton {
  float: right;
  margin-top: 35px;
}