@import "styles/App.scss";

.viewMoreButton {
  color: #323232;

  &:hover {
    color: #323232;
  }

  &:focus {
    color: #323232;
  }

  padding: 0;
  border: 0;
  margin:0;
  height: auto;
}

.unorderedList {
  margin-left: .5em;
  margin-bottom: 0;
}