@import 'styles/App.scss';

$running-status-color: #2e864d;
$pending-status-color: #7fcbed;
$failed-status-color: #da2d30;
$cancelled-status-color: #e6e6e6;
$completed-status-color: #e6e6e6;
$cancelling-status-color: #ffd200;
$scheduled-status-color: #7fcbed;
$triggered-status-color: #7fcbed;

.actionBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-top: 10px;
}
.statusBadge {
  display: flex;
  max-width: 123px;
  min-width: 80px;
  height: 23px;
  border-radius: 17px;
  font-size: 14px;
  text-align: center;
  align-items: center;
  justify-content: center;

  $status-colors: (
    running: $running-status-color,
    failed: $failed-status-color,
    pending: $pending-status-color,
    cancelled: $cancelled-status-color,
    completed: $completed-status-color,
    cancelling: $cancelling-status-color,
    scheduled: $scheduled-status-color,
    triggered: $triggered-status-color,
  );

  @each $key, $status-color in $status-colors {
    &.#{$key} {
      background-color: $status-color;
      @if $key == running {
        color: white;
      } @else if $key == failed {
        color: white;
      } @else {
        color: black;
      }
    }
  }
}
.showCancelUpdateButton {
  display: inline;
  padding-right: 5px;
}

.hideCancelUpdateButton {
  display: none;
}

.failedCancellationMessageButton {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
