@import "~@iqmetrix/antd/dist/accessibility.css";
@import "~@iqmetrix/style-tokens/dist/scss/tokens-accessibility";
@import "./Variables.scss";

.ant-layout-content {
  margin-top: 40px;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  min-height: 60px;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon, .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #ffd200;
}
.ant-checkbox-wrapper,
.ant-radio-wrapper {
  display: inline-block;
}


.ant-table-row.disabled {
  color: $disabledTextColor
}

.ant-table-content
{
  .filtered-search-icon {
    color: $activeFilterColor
  }
}

.ant-table-body {
  overflow-y: auto !important;
  overflow-x: hidden;
}

.ant-dropdown {
    .filter-dropdown {
        padding: 8px;

        button {
            width: 90px;
        }

        overflow-y: scroll;
        max-height: 250px;
    }
}

.ant-btn-link {
  padding: 0;
}

.ant-btn-loading-icon {
  .anticon-spin.anticon {
    margin-right: 8px;
    padding-right: 0;
  }
}

.ant-table-cell-scrollbar {
  box-shadow: 0 0 0 0 #f7f7f7;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.ant-table {
  border: 1px solid #b7b7b7;

  .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }
}

.ant-layout-content > div:first-child > div:first-child > div:nth-child(2) > div:first-child  {
  width: 100%;
}
.ant-select-selection-overflow{
  display: contents;
}

.ant-tabs-content {
  width: clamp(0px, calc(100vw - 64px), 2000px);
}

.replicationText{
  color: #3088bf;
  font-size: smaller;
  font-weight: bold;
  word-wrap: normal;
}