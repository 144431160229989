@import 'styles/App.scss';

.actionBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-top: 10px;
  }

.selectTagsModal{
    min-height: 80px;
}

.tagsSelect{
    width: 100%;
}

.viewSelectedButton {
  color: #3088bf;

  &:hover {
    color: #3088bf;
    text-decoration: none;
  }

  &:focus {
    color: #3088bf;
    text-decoration: none;
  }

  padding: 0;
  border: 0;
  margin:0;
  margin-left: 2em;
  height: auto;
  text-decoration: none;
}