@import 'styles/App.scss';

.confirmDeleteTagModal div:global(.ant-modal-header){
    border-bottom: none;
    font-size: 20px;
}

.confirmDeleteTagModal div:global(.ant-modal-footer){
    border-top: none;
}

.actionBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
  min-height: 52px;
}