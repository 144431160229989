@import "styles/App.scss";

.actionBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
  min-height: 52px;
}
.showAddTagsButton {
  display: inline;
}

.hideAddTagsButton {
  display: none;
}
.addTagsModal{
  min-height: 170px;
}
.tagsSelect{
  width: 100%;
}